import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "./registerServiceWorker";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import vSelect from "vue-select";
import VueSocialSharing from "vue-social-sharing";
// import { LoaderPlugin } from 'vue-google-login'
import VeeValidate from "vee-validate";
Vue.component("v-select", vSelect);

import HighchartsVue from "highcharts-vue";
Vue.use(HighchartsVue);

import Meta from "vue-meta";
Vue.use(Meta);
import cookie from "js-cookie";
Vue.use(cookie);

// Vue.use(LoaderPlugin, {
//   client_id: '883629074374-38kqb0b9051qhi1l3bp2unujlsoqtr1h.apps.googleusercontent.com'
// })
// Vue.GoogleAuth.then(auth2 => {
//   Vue.prototype.$authGoogle = auth2
//   console.log(auth2.isSignedIn.get())
// })
// import bootstrap
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/dist/vue-select.css";
import "@/assets/scss/main.scss";
import "slick-carousel/slick/slick.css";

// validator

Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});
import VueMoment from "vue-moment";
import moment from "moment-timezone";
Vue.use(VueMoment, {
  moment,
});
import axios from "axios";
Vue.prototype.$axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 180000, // 3 menit
});
Vue.config.productionTip = false;

Vue.filter("convertToRupiah", function (angka) {
  var rupiah = "";
  var angkarev = angka.toString().split("").reverse().join("");
  for (var i = 0; i < angkarev.length; i++)
    if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";
  return rupiah
    .split("", rupiah.length - 1)
    .reverse()
    .join("");
});

// short description
import stripHtml from "string-strip-html";
Vue.filter("shotDescription", function (value, length = 150) {
  let string = stripHtml(value);
  if (string.length > length) {
    return string.substring(0, length) + "...";
  } else {
    return string;
  }
});
// format tanggal title berita
Vue.filter("publish_date_news", function (value, type) {
  let data = value.split(" ");
  if (type == "date") {
    return data[0];
  } else {
    return data[1].substring(0, 3);
  }
});

// font
require("./assets/css/iconfont.css");
require("./assets/css/line-awesome-font-awesome.min.css");
// import tailwind
import "@/assets/css/tailwind.css";

import VueNumerals from "vue-numerals";
Vue.use(VueNumerals);
Vue.use(VueSocialSharing);

if (cookie.getJSON("userdata") !== undefined) {
  let UserData = cookie.getJSON("userdata");
  store.commit("SET_LOGIN", UserData);
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vSelect,
  render: (h) => h(App),
}).$mount("#app");
