import request from "@/utils/axios";
import { serialize } from "object-to-formdata";

const getPotensiKampungList = ({ commit }, page) => {
  return new Promise((resolve, reject) => {
    commit("SET_LOADING");
    const params = {
      page: page,
    };
    request
      .post("/v1/profile/get-profile-kampung", null, { params })
      .then((result) => {
        console.log(result);
        commit("SET_POTENSI_KAMPUNG_LIST", result.data);
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        commit("GET_POTENSI_KAMPUNG_LIST_ERROR", error);
        reject("error");
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  });
};

const filterPotensiKampungList = ({ commit }, { form, page }) => {
  return new Promise((resolve, reject) => {
    commit("SET_LOADING");
    const params = {
      page: page,
    };
    const formData = serialize(form);
    request
      .post("/v1/profile/get-profile-kampung", formData, { params })
      .then((result) => {
        console.log(result);
        commit("SET_POTENSI_KAMPUNG_LIST", result.data);
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        commit("GET_POTENSI_KAMPUNG_LIST_ERROR", error);
        reject("error");
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  });
};

const getPotensiKampungById = ({ commit }, village_id) => {
  return new Promise((resolve, reject) => {
    commit("SET_LOADING");
    request
      .get("/v1/profile/get-profile-kampung-single/" + village_id)
      .then((result) => {
        console.log(result);
        commit("SET_POTENSI_KAMPUNG", result.data);
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        commit("GET_POTENSI_KAMPUNG_ERROR", error);
        reject(error);
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  });
};

export default {
  getPotensiKampungList,
  filterPotensiKampungList,
  getPotensiKampungById,
};
