import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import Meta from "vue-meta";

Vue.use(VueRouter);
Vue.use(Meta);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "",
      component: () => import("@/views/main/main.vue"),
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("@/views/Home.vue"),
        },
        {
          path: "/about",
          name: "about",
          component: () => import("../views/About.vue"),
        },
        {
          path: "/berita",
          name: "Berita",
          component: () => import("../views/pages/berita/list.vue"),
        },
        {
          path: "berita/:id",
          name: "Berita Detail",
          component: () => import("../views/pages/berita/detail.vue"),
        },
        {
          path: "data-pokok/data-kependudukan/",
          name: "Data Kependudukan",
          component: () =>
            import("../views/pages/data-pokok/data-kependudukan/data.vue"),
        },
        {
          path: "/data-kabupaten/:id",
          name: "data-kabupaten",
          component: () =>
            import(
              "../views/pages/data-pokok/data-kependudukan/data-kabupaten.vue"
            ),
        },
        {
          path: "/data-distrik/:id",
          name: "data-distrik",
          component: () =>
            import(
              "../views/pages/data-pokok/data-kependudukan/data-distrik.vue"
            ),
        },
        {
          path: "/data-pokok/data-kesehatan",
          name: "Data Kesehatan",
          component: () =>
            import("../views/pages/data-pokok/data-kesehatan/index.vue"),
        },
        {
          path: "/data-pokok/data-pendidikan",
          name: "Data Pendidikan",
          component: () =>
            import("../views/pages/data-pokok/data-pendidikan/index.vue"),
        },
        {
          path: "/data-pokok/data-bantuan-sosial",
          name: "Data Bantuan Sosial",
          component: () =>
            import("../views/pages/data-pokok/data-bantuan-sosial/index.vue"),
        },
        {
          path: "/repository",
          name: "Repository",
          component: () => import("../views/pages/repository/index.vue"),
        },
        {
          path: "/repository/:id",
          name: "Detail Repository",
          component: () => import("../views/pages/repository/detail.vue"),
          meta: {
            active: "repository",
          },
        },
        {
          path: "/potensi-kampung",
          name: "Potensi Kampung",
          component: () => import("../views/pages/potensi-kampung/home.vue"),
          meta: {
            active: "potensi-kampung",
          },
        },
        {
          path: "/potensi-kampung/detail/:id",
          name: "Detail Potensi Kampung",
          component: () => import("../views/pages/potensi-kampung/detail.vue"),
          meta: {
            active: "potensi-kampung",
          },
        },
        {
          path: "/layanan/administrasi-kependudukan",
          name: "Administrasi Kependudukan",
          component: () =>
            import("../views/pages/layanan/administrasi-kependudukan.vue"),
          meta: {
            active: "layanan",
          },
        },

        {
          path: "/bantuan/istilahsaik",
          name: "Istilah dalam SIOPAPUA",
          component: () => import("../views/pages/bantuan/istilahsaik.vue"),
        },
        {
          path: "/bantuan/faq",
          name: "FAQ",
          component: () => import("../views/pages/bantuan/faq.vue"),
        },
        {
          path: "/bantuan/tentangkami",
          name: "tentang kami",
          component: () => import("../views/pages/bantuan/tentangkami.vue"),
          meta: {
            active: "bantuan",
          },
        },
        {
          path: "/bantuan/dokumentasi",
          name: "dokumentasi",
          component: () => import("../views/pages/bantuan/dokumentasi.vue"),
          meta: {
            active: "bantuan",
          },
        },
      ],
    },
    {
      path: "",
      component: () => import("@/views/full-page/full-pages.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/register",
          name: "register",
          component: () => import("@/views/full-page/register.vue"),
          meta: {
            guest: true,
          },
        },
        {
          path: "/not-found",
          name: "notfound",
          component: () => import("@/views/full-page/not-found.vue"),
        },
      ],
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;
