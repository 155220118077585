const mutations = {
  SET_BG(state, val) {
    state.bgSetting = val;
  },
  SET_LOGIN(state, payload) {
    state.userdata = payload;
  },
  DISTRICT_STATE(state, payload) {
    state.districtstate = payload;
  },
};

export default mutations;
