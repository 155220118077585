const SET_POTENSI_KAMPUNG_LIST = (state, payload) => {
  state.potensiKampungs = payload.data;
  state.total = payload.data.total;
};

const SET_POTENSI_KAMPUNG = (state, payload) => {
  console.log("payload:", payload.data[0]);
  state.potensiKampung = payload.data[0];
};

const SET_LOADING = (state, payload = true) => {
  state.loading = payload;
};

const GET_POTENSI_KAMPUNG_LIST_ERROR = (state, payload) => {
  state.getPotensiKampungsError = payload;
};

const GET_POTENSI_KAMPUNG_ERROR = (state, payload) => {
  state.getPotensiKampungError = payload;
};

export default {
  SET_POTENSI_KAMPUNG_LIST,
  SET_POTENSI_KAMPUNG,
  SET_LOADING,
  GET_POTENSI_KAMPUNG_LIST_ERROR,
  GET_POTENSI_KAMPUNG_ERROR,
};
